.search-bar {
          margin: 20px 0;
          display: flex;
          justify-content: center;
        }
        
        .search-input {
          width: 100%;
          max-width: 600px;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid #ccc;
          font-size: 16px;
          outline: none;
        }
        
        .search-input:focus {
          border-color: #ff007f;
        }
        