body {
  background-color: #192437;
  color: #fff;
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.content {
  /* width: 100%; */
  margin-top: 60px; /* Adjust this to account for the navbar height */
}


.pagination-container {
  display: flex;
  justify-content: center; /* Centers the pagination */
  margin-top: 20px;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #e9ecef;
  text-decoration: none;
  cursor: pointer;
}

.page-link:hover {
  background-color: #d78e2f;
}

.active .page-link {
  background-color: #d78e2f;
  color: white;
  border: 1px solid #d78e2f;
}
.button-container {
  display: flex;
  justify-content: flex-end; /* Aligns the button to the right */
  margin-bottom: 20px; /* Add space below the button */
}

.random-button {
  background-color: #f39c12; /* Orange background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding for the button */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth background color transition */
}

.random-button:hover {
  background-color: #e67e22; /* Darker orange on hover */
}

.random-button:focus {
  outline: none; /* Remove the default focus outline */
}

.random-button:active {
  background-color: #d35400; /* Even darker orange when clicked */
}

.filter-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.dropdown-container {
  margin: 0 10px;
}

#category-dropdown, #genre-dropdown {
  padding: 5px;
  border-radius: 5px;
}
/* Horizontal layout for dropdowns */
.filter-container {
  display: flex;
  justify-content: center; /* Align dropdowns horizontally */
  align-items: center;
  flex-wrap: wrap; /* Allows wrapping if the screen size is too small */
  gap: 20px; /* Space between dropdowns */
  margin-bottom: 20px;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
}

.dropdown-container select {
  padding: 8px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 150px; /* Fixed width for all dropdowns */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .filter-container {
    flex-direction: row; /* Stack dropdowns vertically on smaller screens */
    gap: 10px;
  }

  .dropdown-container select {
    width: 100%; /* Full width on smaller screens */
  }
}
